import {
    BOGE_FILTERS_STRINGS,
    BOGE_FILTERS_NUMBERS,
    BOGE_FILTERS_ARRAYS,
    BOGE_FILTERS_BOOLEANS,
} from '../constants/productSearchSessionName';

export const getIsProductsSearchFiltersOpened = (): boolean =>
    typeof sessionStorage !== 'undefined' ? sessionStorage.getItem(BOGE_FILTERS_ARRAYS) !== null : false;

export const getFilterStringFromSessionStorage = (filter: String): string => {
    if (typeof sessionStorage !== 'undefined' && JSON.parse(sessionStorage.getItem(BOGE_FILTERS_STRINGS)) !== null) {
        const filtersStrings = JSON.parse(sessionStorage.getItem(BOGE_FILTERS_STRINGS));
        //@ts-ignore;
        return filtersStrings[filter];
    } else {
        return '';
    }
};

export const getFilterArraySessionStorage = (filter: String): Array<any> => {
    if (typeof sessionStorage !== 'undefined' && JSON.parse(sessionStorage.getItem(BOGE_FILTERS_ARRAYS)) !== null) {
        const filtersStrings = JSON.parse(sessionStorage.getItem(BOGE_FILTERS_ARRAYS));
        //@ts-ignore;
        return filtersStrings[filter];
    } else {
        return [];
    }
};

export const getFilterNumberSessionStorage = (filter: String, filterValue?: number): number => {
    if (typeof sessionStorage !== 'undefined' && JSON.parse(sessionStorage.getItem(BOGE_FILTERS_NUMBERS)) !== null) {
        const filtersStrings = JSON.parse(sessionStorage.getItem(BOGE_FILTERS_ARRAYS));
        //@ts-ignore;
        return filtersStrings[filter];
    } else {
        if (filter === 'motorPowerMaxFilter' || filter === 'fedMaxFilter') {
            return filterValue;
        }
        return 0;
    }
};

export const getFilterBooleanSessionStorage = (filter: String): boolean => {
    if (typeof sessionStorage !== 'undefined' && JSON.parse(sessionStorage.getItem(BOGE_FILTERS_NUMBERS)) !== null) {
        const filtersStrings = JSON.parse(sessionStorage.getItem(BOGE_FILTERS_BOOLEANS));
        //@ts-ignore;
        return filtersStrings[filter];
    } else {
        return false;
    }
};
