import { useContext, useEffect, useState } from 'react';
import { ProductSearchResultsContext } from '../context/ProductSearchResultsContext';
import { TABLET_SCREEN_SIZE } from '../styles/MediaQuery';

const useBrowserWidth = () => {
    const [browserWidth, setBrowserWidth] = useState(0);
    const { setIsMobileView } = useContext(ProductSearchResultsContext);

    useEffect(() => {
        if (browserWidth > TABLET_SCREEN_SIZE) {
            setIsMobileView(false);
        }
    }, [browserWidth]);

    useEffect(() => {
        const handleResize = () => setBrowserWidth(window.innerWidth);

        if (typeof window !== 'undefined') {
            handleResize();
            window.addEventListener('resize', handleResize);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', handleResize);
            }
        };
    }, []);

    return browserWidth;
};

export default useBrowserWidth;
