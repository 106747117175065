import {
    FiltersStringsInterface,
    FiltersNumbersInterface,
    FiltersArraysInterface,
    FiltersBooleansInterface,
} from '../interfaces/productSearch';

export const filtersStringsExample: FiltersStringsInterface = {
    searchFilter: '',
    typeFilter: '',
    modeFilter: '',
    currentCategoryName: '',
    currentCategoryId: '',
    compressionModeUnits: '',
};

export const filtersNumbersExample: FiltersNumbersInterface = {
    numberOfResults: 0,
    pressureRangeFilter: 0,
    heatRecoveryFilter: 0,
    currentOffset: 0,
    motorPowerMinFilter: 0,
    motorPowerMaxFilter: 0,
    fedMinFilter: 0,
    fedMaxFilter: 0,
};

export const filtersArraysExample: FiltersArraysInterface = {
    itemsOfProducts: [],
    soundAbsorptionFilter: [],
    deliveryControlFilter: [],
    coolingFilter: [],
    otherFilter: [],
};

export const filtersBooleansExample: FiltersBooleansInterface = {
    isLoading: false,
    isFirstFetch: false,
    isFiltersSubmit: false,
    isMobileView: false,
    isProductsSearchFiltersOpened: false,
};
