import { availableUrlParams } from '../constants/availableUrlParams';
import { categoryNamesWithIdObject } from '../templateObjects/categoryNamesWithIdObject';
import { getFilterStringFromSessionStorage } from '../utils/getDataFromSessionStorage';

export const urlHasProductSearchParams = (url_string: string, urlParams): boolean => {
    if (url_string.length > 1 && urlParams[1] && urlParams[1] !== '') {
        if (
            urlParams[1]
                .split('&')
                .map((param) => param.slice(0, param.indexOf('=')))
                .some((param) => availableUrlParams.includes(param))
        ) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const getCategoryName = (urlParams, categoryNamesWithId, hasUrlParams): string => {
    if (urlParams?.has('field_product_main_search')) {
        return urlParams?.get('field_product_main_search');
    } else if (urlParams?.has('field_product_main_search_tid') && !urlParams?.has('field_product_main_search')) {
        if (categoryNamesWithId?.length === 0) {
            return categoryNamesWithIdObject.find((x) => x.id === urlParams?.get('field_product_main_search_tid')).name;
        } else {
            return categoryNamesWithId.find((x) => x.id === urlParams?.get('field_product_main_search_tid'))?.name;
        }
    } else {
        return !hasUrlParams ? getFilterStringFromSessionStorage('currentCategoryName') : '';
    }
};

export const getCategoryId = (urlParams, categoryNamesWithId, hasUrlParams): string => {
    if (urlParams?.has('field_product_main_search_tid')) {
        return urlParams?.get('field_product_main_search_tid');
    } else if (!urlParams?.has('field_product_main_search_tid') && urlParams?.has('field_product_main_search')) {
        if (categoryNamesWithId?.length === 0) {
            return categoryNamesWithIdObject.find((x) => x.name === urlParams.get('field_product_main_search'))?.id;
        } else {
            return categoryNamesWithId.find((x) => x.name === urlParams.get('field_product_main_search'))?.id;
        }
    } else {
        return !hasUrlParams ? getFilterStringFromSessionStorage('currentCategoryId') : '';
    }
};
