export const availableUrlParams = [
    'title',
    'field_product_compressor_type',
    'field_product_compression_mode',
    'field_product_pressure_range',
    'field_product_heat_recovery',
    'field_product_main_search',
    'field_product_main_search_tid',
    'compression_mode_units',
    'field_product_motor_power_kw_value[min]',
    'field_product_motor_power_kw_value[max]',
    'field_product_fed_value[min]',
    'field_product_fed_value[max]',
    'field_product_compressor_type_tid',
    'field_product_compression_mode_tid',
];
